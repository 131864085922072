import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import allamimg from "../../static/images/allamimg.jpg"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About allam blog" />
      <div id="about">
        <h2>This Is Me </h2>
        <br />
        <img src={allamimg} alt="allam photo" />
        <p> </p>
        <p>
        My name is Mahmoud. 23 years old and Egyptian fresh graduate dentist. First of all, and the most important part of my About is: I'm so imaginative person. Every step I take, every decision I make and even every place I go, I always imagine what this thing looks like? How it would be in the future or what could be the results? Most of my work relies on imagination.  I am inspired by almost everything that happens around me and that's what fuels my soul to keep going. 
        </p>

        <p>
        I consider myself as a leader for science and education as I believe that education has a lot of benefits and positive impact on people's lives. An educated person is an asset to any community. I love astronomy, nature, exploring new things and learning new stuff. 
        </p>

        <p>
        Now, I'm working as a social media specialist and as an operations manager in Tayyebat, one of the promising projects that belong to the Ibn Sina training academy and concerns about healthy food and nutrition.
        </p>

        <p>
        You can find more about me on Linkedin.
        </p>
        <p>References and CV are available upon request.</p>
        <a
              target="_blank"
              href="mailto:info@mahmoudallam.me"
              rel="noopener noreferrer"
            >
        <div className="about-icons">
          <span role="img" aria-label="Laptop.">
            📧
          </span>
        </div>
        Mail</a>
        <h3>Thanks for visiting!!</h3>
      </div>
    </Layout>
  )
}
export default AboutPage
